import React, { useReducer } from 'react';
import DashboardContext from './dashboard-context';

const defaultProjectState = {
  dates: '24h',
  rangesArray: [],
};

const projectReducer = (state, action) => {
  switch (action.type) {
    case 'DATES':
      return { ...state, dates: action.payload };

    case 'RANGESARRAY':
      return { ...state, rangesArray: action.payload };

    default:
      throw new Error();
  }
};

const DashboardProvider = (props) => {
  const [state, dispatch] = useReducer(projectReducer, defaultProjectState);

  return (
    <DashboardContext.Provider value={[state, dispatch]}>
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
