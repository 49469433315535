import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/en.json';
import esTranslation from './locales/es.json';
import ruTranslation from './locales/ru.json';

const resources = {
  en: { translation: enTranslation },
  es: { translation: esTranslation },
  ru: { translation: ruTranslation },
};

const options = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
  addPath: '/locales/add/{{lng}}/{{ns}}',
  allowMultiLoading: false,
  parse: function (data) {
    return data.replace(/a/g, '');
  },
  parsePayload: function (_namespace, key, _fallbackValue) {
    return { key };
  },
  crossDomain: false,
  withCredentials: false,
  overrideMimeType: false,
  customHeaders: {
    authorization: 'foo',
  },
  requestOptions: {
    mode: 'cors',
    credentials: 'same-origin',
    cache: 'default',
  },
  queryStringParams: { v: '1.3.5' },
  reloadInterval: false,
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'es', 'ru'],
    debug: false,
    load: 'languageOnly',
    backend: {
      options,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
